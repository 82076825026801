import { useState } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import EstimateForm from './estimate-form'
import Popup from 'reactjs-popup'

import Logo from '../components/logo-svg'
import { X } from 'react-feather'

let windowWidth = 0
if (typeof window !== `undefined`) {
  windowWidth = window.innerWidth
}

const MainNavigation = ({ mainNavigation, pageLocation, level = 0 }) => {
  const menuItems = mainNavigation.map((menuItem, index) => {
    const isParentOnSubPage =
      level == 0 &&
      menuItem.submenu &&
      menuItem.submenu[0].menuItems.filter(({ subMenuSection, url }) => {
        return (
          pageLocation.includes(url) ||
          (subMenuSection &&
            subMenuSection[0].menuItems.filter(({ url }) => url == pageLocation)
              .length > 0)
        )
      }).length > 0
    return (
      <li
        key={index}
        className={
          'menu' +
          (menuItem.submenu ? ' has-submenu ' : '') +
          (menuItem && menuItem.cssClasses ? menuItem.cssClasses : '')
        }
      >
        <Link
          to={
            menuItem.url
              ? menuItem.url[0] !== '/'
                ? '/' + menuItem.url
                : menuItem.url
              : '#'
          }
          title={menuItem.title}
          activeClassName={'active'}
          partiallyActive={menuItem.url != '/'}
          className={`${isParentOnSubPage ? 'active' : ''}`}
        >
          {menuItem.name}
        </Link>

        {/* render submenu */}
        {menuItem && menuItem.submenu ? (
          <ul className="submenu bg-color-primary-lg">
            <MainNavigation
              mainNavigation={menuItem.submenu[0].menuItems}
              level={level + 1}
            />
          </ul>
        ) : (
          ''
        )}
      </li>
    )
  })
  return menuItems
}

const CallToAction = (props) => {
  const [open, setOpen] = useState(false)
  const closeModal = () => setOpen(false)

  return (
    <div className="cta-section-wrapper">
      <div className="headeroneph">
        <span className="givecalltoday">
        Call Us Today!

          <br />
        </span>
        <a
          href={'tel:' + props.companyInfo.phone}
          className="phone-number-header headerphone color-primary hover:color-primary-dark"
        >
          {props.companyInfo.phone}
        </a>
      </div>
      <button
        title={'FREE Estimate →'}
        style={{ borderWidth: '0' }}
        className="border-radius button-style-primary button-style-secondary headerbutton button-style-white-outline border-0 cursor-pointer primary cta-button"
        onClick={() => setOpen((o) => !o)}
      >
        FREE Estimate
      </button>
      <Popup open={open} modal closeOnDocumentClick onClose={closeModal}>
        {(close) => (
          <div className="p-2 block">
            <button
              style={{ borderWidth: '0', float: 'right' }}
              className="cursor-pointer d-block border-radius mt-3 mr-4 p-2 float-right m0-auto m-0-auto button__ButtonOuter-gFbSyU"
              onClick={() => {
                close()
              }}
            >
              <X color={'#333'} strokeWidth={'3'} className="d-block" />
            </button>
            <div className="p-3 py-4 block">
              <h2 className="mb-1 popupheading">Get A Free Estimate Now!</h2>
              <p
                style={{
                  color: '#666',
                  lineHeight: '1.40',
                  fontSize: '.9em',
                }}
                className="mb-3 popupdesc"
              >
                Want to get started? Just fill out the form below for a free, no-obligation estimate and expert advice. We're here to help clarify any questions you may have along the way.
              </p>
              <EstimateForm
                showServicesList={true}
                showLabels={true}
                showPlaceHolders={true}
                pageLocation={
                  props.pageProps.location.href
                    ? props.pageProps.location.href
                    : 'Not provided'
                }
              />
            </div>
          </div>
        )}
      </Popup>
    </div>
  )
}
class Header extends React.Component {
  constructor(props) {
    super(props)
    this.toggleMobileMenuOverlay = this.toggleMobileMenuOverlay.bind(this)
    this.state = { headerOpen: false }
  }

  componentDidMount() {
    this.updatePhoneLoadScrollReveal()
  }
  componentDidUpdate() {
    this.updatePhoneLoadScrollReveal()
  }

  updatePhoneLoadScrollReveal = () => {
    window.CallTrk.swap()
  }

  toggleMobileMenuOverlay = () => {
    this.setState({
      headerOpen: (this.headerOpen = !this.headerOpen),
    })

    if (document.body.classList.contains('mobile-menu-overlay--open')) {
      document.body.classList.remove('mobile-menu-overlay--open')
    } else {
      document.body.classList.add('mobile-menu-overlay--open')
    }
  }

  render() {
    return (
      <>
      {this.props.globalOffer && (
          <div className="notification-designer">
            <div className="container p-3 text-center font-bold">
              <span>
                {this.props.globalOffer.customNotificationBarText
                  ? this.props.globalOffer.title
                  : this.props.globalOffer.customNotificationBarText}
                &nbsp;
                <Link to={'/offers/' + this.props.globalOffer.slug + '/'}>
                  {this.props.globalOffer.customNotificationBarActionText
                    ? this.props.globalOffer.customNotificationBarActionText
                    : 'Learn more'}{' '}
                  →
                </Link>
              </span>
            </div>
          </div>
        )}
        <header className="outer-container container-fluid bg-color-light bg-color-primary-under-992">
          <div className="container padding-0">
            <div className="row">
              <div className="logo-container col-6 col-lg-3 d-flex align-items-center">
                <Link to="/" style={{ lineHeight: '0' }}>
                  <div
                    className="logo-svg"
                    alt={this.props.siteTitle + ' Logo'}
                  >
                    <Logo />
                  </div>
                </Link>
              </div>

              {this.props.siteDescription ? (
                <div className="site-tag-line sitetaglinehead divcentervertical d-xl-flex col-xl-4 col-lg-3">
                  <p className="sitelinehead">
                    Columbus Home Renovation Experts
                  </p>
                  <p className="areasservedheader">
                    {this.props && this.props.siteDescription}
                  </p>
                </div>
              ) : null}

              <div className="d-none d-lg-flex align-items-center col-lg-5 justify-content-end">
                <CallToAction {...this.props} />
              </div>

              <di className="d-lg-none d-xl-none col-6 d-flex align-items-center justify-content-end">
                <button
                  className="mobile-icon-burger"
                  onClick={this.toggleMobileMenuOverlay}
                >
                  {this.state.headerOpen ? (
                    <>
                      <X size={30} color={'#000'} />
                      <span className="sr-only">Close Menu</span>
                    </>
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        fill="#000"
                        stroke="#000"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <line x1="5" y1="12" x2="23" y2="12"></line>
                        <line x1="2" y1="6" x2="23" y2="6"></line>
                        <line x1="8" y1="18" x2="23" y2="18"></line>
                      </svg>
                      <span className="sr-only">Open Menu</span>
                    </>
                  )}
                </button>
              </di>
            </div>
          </div>

          <div className="site-tag-line mobileareaserved container padding-0">
            <p>{this.props && this.props.siteDescription}</p>
          </div>
        </header>

        <nav className="outer-container menu-desktop d-none d-lg-flex align-items-center  bg-color-primary">
          <div className="container">
            <ul>
              <MainNavigation
                pageLocation={this.props.pageProps.location.pathname}
                mainNavigation={this.props.mainNavigation}
                refCallback={this.refCallback}
              />
            </ul>
          </div>
        </nav>

        <div className="mobile-secondary-cta d-lg-none d-xl-none text-color-primary mobileheadersec">
          <div className="container">
            <CallToAction
              {...this.props}
              className="d-lg-none d-xl-none col-6 d-flex align-items-center"
            />
          </div>
        </div>

        <div
          className={'mobileOverlay' + (this.state.headerOpen ? ' open' : '')}
        >
          <div className="animate-overlay-content">
            <ul className="mobile-navigation">
              <MainNavigation
                mainNavigation={this.props.mainNavigation}
                pageLocation={this.props.pageProps.location.pathname}
              />
            </ul>
          </div>
        </div>
      </>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string,
  mainNavigation: PropTypes.array,
}

Header.defaultProps = {
  siteTitle: '',
  siteDescription: '',
  mainNavigation: '',
}

export default Header
