import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Header from './header'
import Footer from './footer'
import ProductLogoShowcase from './product-logo-showcase'
import './layout.css'
import '../fonts/fonts.css'
import '../styles/index.scss'
import '../styles/custom.scss'
import '../styles/output.css'

const LayoutContainer = styled.div``
const Layout = ({
  children,
  pageProps,
  footerLogoShowcase,
  isHomePage,
  customSchema,
  servicePage,
}) => {
  //capture initial landing URL & date
  if (
    typeof window !== 'undefined' &&
    window.localStorage &&
    !window.localStorage.getItem('landingURL')
  ) {
    window.localStorage.setItem('landingURL', pageProps.location.href)
    window.localStorage.setItem('landingDate', new Date())
  }
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              description
              companyInfo {
                phone
                email
                addressLine1
                addressLine2
                city
                state
                zip
                license
              }
            }
          }
          contentfulOffer(featuredGlobalOffer: { eq: true }) {
            id
            title
            slug
            featuredGlobalOffer
            customNotificationBarActionText
            customNotificationBarText
          }
          allContentfulNavigation(filter: { name: { eq: "Main Navigation" } }) {
            edges {
              node {
                id
                name
                menuItems {
                  id
                  name
                  title
                  url
                  openInNewWindow
                  submenu {
                    id
                    name
                    menuItems {
                      id
                      name
                      title
                      url
                      openInNewWindow
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <>
          <Header
            globalOffer={data.contentfulOffer}
            siteTitle={data.site.siteMetadata.title}
            siteDescription={data.site.siteMetadata.description}
            companyInfo={data.site.siteMetadata.companyInfo}
            mainNavigation={
              data.allContentfulNavigation.edges[0].node.menuItems
            }
            pageProps={pageProps}
          />
          <LayoutContainer>{children}</LayoutContainer>
          <ProductLogoShowcase footerLogoShowcase={footerLogoShowcase} />

          <Footer isHomePage={isHomePage} servicePage={servicePage} />
          {!customSchema && (
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: `
                    {
                      "@id": "https://www.usqualityofcolumbus.com#HomeAndConstructionBusiness",
                      "@context": "https://www.schema.org",
                      "@type": "HomeAndConstructionBusiness",
                      "name": "US Quality Construction of Columbus",
                      "url": "https://www.usqualityofcolumbus.com/",
                      "logo": "https://images.ctfassets.net/ml5xp0ucm4ah/573COoVFKGRFBMAl0O6PQc/ec745cb7ce4b29ecac22961387dd3e62/us-quality-logo-columbus.svg",
                      "image": "https://images.ctfassets.net/ml5xp0ucm4ah/7APjtPU95L7UPyQM2sW1IG/49a93bc15f3ce1d9d0b5a2260ffbdd42/wood-siding2.jpeg",
                      "description": "US Quality Construction is a Local Siding Contractor Specializing in Siding Replacement, Siding Installation, Vinyl Siding, Decks, Replacement Windows & More.",
                      "address": {
                          "@type": "PostalAddress",
                          "streetAddress": "2025 S High St Suite 112",
                          "addressLocality": "Columbus",
                          "addressRegion": "OH",
                          "postalCode": "43205",
                          "addressCountry": "USA"
                      },
                      "geo": {
                          "@type": "GeoCoordinates",
                          "latitude": "39.959990",
                          "longitude": "-82.978370"
                      },
                      "hasMap": "",
                      "openingHours": "Mo, Tu, We, Th, Fr 10:00-17:00",
                      "telephone": "+1 614-364-4543",
                      "priceRange":"$$",  
                      "contactPoint": {
                          "@type": "ContactPoint",
                          "contactType": "sales",
                          "telephone": "+1 614-364-4543"
                      }
                  }
                  `,
              }}
            />
          )}
        </>
      )}
    />
  )
}
Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
